<template>
  <div class="body-wrap d-flex ai-center mx-30">
    <div class="page-show"></div>
    <div class="demo-introduce flex-grow-1">

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.body-wrap {
  width: 80vw;
  margin: 0 auto;
  height: 100vh;
  .page-show {
    width: 40rem;
    height: 80rem;
    border: 1px solid black;
  }
  .demo-introduce{
      height: 100vh;
  }
}
</style>